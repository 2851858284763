import React, {useState, useEffect, useRef, SetStateAction, ChangeEvent} from 'react';
import './EstimateTreeDetailComponent.css'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent, CardHeader,
  CardMedia, Chip, CircularProgress,
  Dialog, DialogActions,
  DialogContent, DialogTitle, Divider, FormControl,
  IconButton, InputLabel, MenuItem, Select, SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {updateImage, uploadImage} from "../../shared/services/image-service";
import {EstimateTreeDto, UpdateEstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";
import {
  createTreeOperation,
  deleteTree,
  deleteTreeOperation,
  getTree,
  getTreeOperations,
  TreeOperationSchema,
  updateTree,
  updateTreeOperation
} from "./estimate-tree.service"
import {EstimateTreeOperationDto, InputEstimateTreeOperationDto, TreeOperationType} from "../../shared/dtos/estimate-tree-operation.dto";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {logError} from "../../shared/services/logger.service";
import {DrawableImage} from "./DrawableImage";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {ImagePreviews} from "../../shared/components/ImagePreviews";
import {ExternalCompanyDto} from "../../shared/dtos/company.dto";
import {InputSchema, buildInput, InputDataType} from "../../shared/services/schema-based-inputs.service";
import {getUserTokenOrRedirect} from "../../shared/services/auth.service";
import {useRealTime} from "../../shared/providers/real-time.provider";
import {convertPngToJpeg, uploadImagesFromEvent} from "../../shared/misc/image-helpers";

export interface ImagePreview {
  id: number,
  s3Url: string,
}

export interface EstimateTreeProps {
  tree: EstimateTreeDto,
  treeLocalId: number,
  company: ExternalCompanyDto,
  onNewTree: (updatedGate: EstimateTreeDto) => void,
  onNewGate: (updatedTree: EstimateTreeDto) => void,
  onFinishEstimate: (updatedTree: EstimateTreeDto) => void,
  onTreeDelete: () => void
}

const EstimateTreeDetail: React.FC<EstimateTreeProps> = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const realTimeService = useRealTime();
  const estimateTreeToUpdatedTree = (tree: EstimateTreeDto): UpdateEstimateTreeDto => {
    return {
      ...tree,
      height: tree.height ?? '0 Feet',
      width: tree.width ?? '0 Feet',
      trunkDiameter: tree.trunkDiameter ?? '0 Inches',
      treeId: tree.id,
      updatedBy: tree.createdBy,
      imageIds: tree.images.map(image => image.id),
    }
  }

  // This setup logic needs to be duplicated in the useEffect so the component re-renders properly, we do it here to make the type system happy
  const [savedTree, setSavedTree] = useState<EstimateTreeDto>(props.tree);
  const [updatedTree, setUpdatedTree] = useState<UpdateEstimateTreeDto>(estimateTreeToUpdatedTree(props.tree));
  const [treeImagePreviews, setTreeImagePreviews] = useState<ImagePreview[]>(savedTree.images.map(image => ({
    id: image.id,
    s3Url: image.imageUrl
  })));
  const [countTreeImagesLoading, setCountTreeImagesLoading] = useState<number>(0);
  const [treeImageIsUpdating, setTreeImageIsUpdating] = useState<boolean>(false);

  const [imageDialogIsOpen, setImageDialogIsOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImagePreview>();

  const [savedOperationsDetails, setSavedOperationsDetails] = useState<EstimateTreeOperationDto[]>([]);
  const [updatedOperationsDetails, setUpdatedOperationsDetails] = useState<(InputEstimateTreeOperationDto | undefined)[]>([]);
  const [selectedUpdatedOperationDetails, setSelectedUpdatedOperationDetails_Internal] = useState<InputEstimateTreeOperationDto>();
  const [selectedOperationIndex, setSelectedOperationIndex_Internal] = useState<number>(0);

  const [operationIsLoading, setOperationIsLoading] = useState<boolean>(false);
  const [operationImagePreviews, setOperationImagePreviews] = useState<ImagePreview[]>([]);
  const [countOperationImagesLoading, setCountOperationImagesLoading] = useState<number>(0);
  const [operationImageIsUpdating, setOperationImageIsUpdating] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const setSelectedOperationIndex = (index: number) => {
    setSelectedOperationIndex_Internal(index);
    setSelectedUpdatedOperationDetails_Internal(updatedOperationsDetails[index]);
  }
  // We use this intermediary object for the active operation because array update change detection is noticeably slower in the UI
  const setSelectedUpdatedOperationDetails = (updater: (prev: InputEstimateTreeOperationDto | undefined) => InputEstimateTreeOperationDto) => {
    setUpdatedOperationsDetails(operations => {
      const prevOperation = operations[selectedOperationIndex];
      const newOperation = updater(prevOperation);
      operations[selectedOperationIndex] = newOperation;
      setSelectedUpdatedOperationDetails_Internal(newOperation);
      return operations;
    })
  };

  useEffect(() => {
    setOperationIsLoading(true);

    setSavedTree(props.tree);
    setUpdatedTree(estimateTreeToUpdatedTree(props.tree));
    setTreeImagePreviews(props.tree.images.map(image => ({
      id: image.id,
      s3Url: image.imageUrl
    })));

    setSavedOperationsDetails([]);
    setOperationImagePreviews([]);

    getTreeOperations(navigate, props.tree.estimateId, props.tree.id)
      .then(operations => {
        const sortedOperations = operations.sort((a, b) => a.id > b.id ? 1 : -1);
        setSavedOperationsDetails(sortedOperations);
        const updatedOperationsDetails = sortedOperations.map(o => ({...o, imageIds: o.images.map(i => i.id)}));
        setUpdatedOperationsDetails(updatedOperationsDetails);
        setSelectedUpdatedOperationDetails_Internal(updatedOperationsDetails[0]);
        setOperationImagePreviews(sortedOperations[0]?.images.map(image => ({
          id: image.id,
          s3Url: image.imageUrl
        })) ?? []);
      })
      .catch(e => {
        logError('Error retrieving tree operations', {}, e)
        enqueueSnackbar(`Error retrieving tree operations: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setOperationIsLoading(false));

  }, [props.tree]);

  realTimeService.onMeasuredTreeImageCreated((treeId: number, measuredTreeImageId: number, sqUrl: string, widthFeet: number, heightFeet: number) => {
    console.log('MeasuredTreeImageCreated', treeId, measuredTreeImageId, sqUrl, widthFeet, heightFeet);
    if(treeId === savedTree.id) {
      const newImagePreview = {id: measuredTreeImageId, s3Url: sqUrl}
      const roundedWith = Math.round(widthFeet);
      const roundedHeight = Math.round(heightFeet);
      setUpdatedTree(prevTree => ({...prevTree, width: `${roundedWith} Feet`, height: `${roundedHeight} Feet`}));
      updateTreeImagesInState([newImagePreview]);
    }
  });

  const updateTreeImagesInState = (newImagePreviews: ImagePreview[]) => {
    setTreeImagePreviews((prevImages) => [
      ...prevImages.filter(image => !newImagePreviews.map(newPreview => newPreview.id).includes(image.id)),
      ...newImagePreviews]);
    setUpdatedTree(prevTree => {
      return {
        ...prevTree!,
        imageIds: [
          ...prevTree!.imageIds.filter(id => !newImagePreviews.map(newPreview => newPreview.id).includes(id)),
          ...newImagePreviews.map(file => file.id)]
      }
    });
  }

  const updateOperationImagesInState = (newImagePreviews: ImagePreview[]) => {
    setOperationImagePreviews((prevImages) => [
      ...prevImages.filter(image => !newImagePreviews.map(newPreview => newPreview.id).includes(image.id)),
      ...newImagePreviews]);
    setSelectedUpdatedOperationDetails(prevOperation => {
        return {
          ...prevOperation!,
          imageIds: [
            ...(prevOperation!.imageIds ?? []).filter(id => !newImagePreviews.map(file => file.id).includes(id)),
            ...newImagePreviews.map(file => file.id)]
        }
      });
  }

  const operationIsValid = (operation: InputEstimateTreeOperationDto | undefined) => {
    return operation?.operationType && (operation?.operationType === TreeOperationType.Debris_Management || operation?.estimatedCost)
  }

  const selectedOperationIsValid = () => {
    return operationIsValid(selectedUpdatedOperationDetails);
  }

  const allOperationsValid = () => {
    return updatedOperationsDetails.every(operationIsValid);
  }

  const handleSelectedOperationChipClick = (index: number) => {
    setSelectedOperationIndex(index);
  }

  const handleAddAnotherOperationClick = () => {
    setUpdatedOperationsDetails(operationDetails => {
      setSelectedOperationIndex(operationDetails.length);
      return [...operationDetails, undefined]
    });
  }

  const handleDeleteOperationClick = () => {
    setUpdatedOperationsDetails(operations => {
      const newIndex = selectedOperationIndex > 0 ? selectedOperationIndex - 1 : 0;
      const newSelectedElementCurrentIndex = selectedOperationIndex > 0 ? selectedOperationIndex - 1 : 1;
      setSelectedUpdatedOperationDetails_Internal(operations[newSelectedElementCurrentIndex]);
      setSelectedOperationIndex_Internal(newIndex);
      return operations.filter((_, i) => i !== selectedOperationIndex);
    });
  }

  const handleTreeImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await uploadImagesFromEvent(event, setCountTreeImagesLoading, updateTreeImagesInState);
  };

  const handleTreeImageUpdated = async (id: number, file: File) =>
    genericUpdateImage(id, file, setTreeImageIsUpdating, (preview: ImagePreview) => updateTreeImagesInState([preview]))

  const handleOperationImageUpdated = async (id: number, file: File) =>
    genericUpdateImage(id, file, setOperationImageIsUpdating, (preview: ImagePreview) => updateOperationImagesInState([preview]))

  const genericUpdateImage = async (id: number, file: File, isUpdatingSetter: (isUpdating: boolean) => void, onUpdateComplete: (preview: ImagePreview) => void) => {
    isUpdatingSetter(true);
    await updateImage(id, file)
      .then(result => {
        const newImagePreview = {id: result.id, s3Url: result.imageUrl}
        onUpdateComplete(newImagePreview);
        closeImageDialog();
      })
      .catch(e => {
        logError('Error updating image', {}, e);
        enqueueSnackbar(`Error updating image: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => isUpdatingSetter(false));
  }

  const handleImageUpdated = async (id: number, file: File) => {
    if(treeImagePreviews.find(image => image.id === id)) {
      return handleTreeImageUpdated(id, file);
    }
    if(operationImagePreviews.find(image => image.id === id)) {
      return handleOperationImageUpdated(id, file);
    }
    logError('Attempted to update an image that does not exist in state');
  }

  const handleOperationTypeChange = (event: SelectChangeEvent) => {
    const newOperation: InputEstimateTreeOperationDto = { treeId: savedTree.id, operationType: event.target.value as TreeOperationType, details: {} }
    setSelectedUpdatedOperationDetails(() => newOperation);
    if(newOperation.operationType === 'Stump Grinding') {
      const stumpGrindingWaiverText = `\n\n${props.company.name} will not be liable for any damage to underground utilities or sprinkler pipes that may occur during the stump removal process`;
      setUpdatedTree(prevTree => (
        {...prevTree!, notes: prevTree.notes?.includes(stumpGrindingWaiverText) ? prevTree.notes : `${prevTree?.notes ?? ''} ${stumpGrindingWaiverText}`}));
    }
  }

  const handleEstimatedCostChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedUpdatedOperationDetails(prevDetails => ({...prevDetails!, estimatedCost: event.target.value ? parseInt(event.target.value) : undefined}));
  };

  const handleTreeHeightChange = (event: SelectChangeEvent) => {
    setUpdatedTree(tree => ({...tree!, height: event.target.value}));
  };

  const handleTreeWidthChange = (event: SelectChangeEvent) => {
    setUpdatedTree(tree => ({...tree!, width: event.target.value}));
  };

  const handleTreeTrunkDiameterChange = (event: SelectChangeEvent) => {
    setUpdatedTree(tree => ({...tree!, trunkDiameter: event.target.value}));
  };

  const handleTreeSpeciesChange = (value: string) => {
    setUpdatedTree(tree => ({...tree!, species: value}));
  };

  const handleCrewNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedTree(tree => ({...tree!, crewNotes: event.target.value}));
  };

  const saveTreeAndExecute = async (loadingSetter: (isLoading: boolean) => void, action: (tree: EstimateTreeDto) => void) => {
    loadingSetter(true);
    await upsertAndSetOperations()
      .then(async () => {
        const newTree = await updateTree(navigate, updatedTree.estimateId, updatedTree.treeId, updatedTree!);
        setSavedTree(newTree);
        setUpdatedTree(estimateTreeToUpdatedTree(newTree));
        action(newTree);
      })
      .catch(e => {
        logError('Error saving tree details', {}, e);
        enqueueSnackbar(`Error saving tree details: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => loadingSetter(false));
  }

  const upsertAndSetOperations = async () => {
    if(!updatedOperationsDetails) return;
    const operationIdsToDelete = savedOperationsDetails.filter((operation) => !updatedOperationsDetails.find(updatedOperation => updatedOperation?.id == operation.id)).map(o => o.id);
    const deletePromises = operationIdsToDelete.map(async id => await deleteTreeOperation(navigate, savedTree.estimateId, savedTree.id, id));
    await Promise.all(deletePromises);

    // We don't do this in parallel so the operations save in the correct order
    for(let operation of updatedOperationsDetails) {
      try {
        const newOperation = operation?.id
          ? await updateTreeOperation(navigate, savedTree.estimateId, savedTree.id, operation?.id, operation)
          : await createTreeOperation(navigate, savedTree.estimateId, savedTree.id, operation!)
        setSavedOperationsDetails(operations => {
          operations[selectedOperationIndex] = newOperation;
          return operations;
        });
      } catch (e) {
        logError('Error saving tree operation', {}, e as Error);
        enqueueSnackbar(`Error saving tree operation: ${e}`, {variant: 'error', autoHideDuration: 5000});
      }
    }
  }

  const removeTreeImage = (index: number) => {
    setTreeImagePreviews((prevImages) => prevImages.filter((_, i) => i !== index));
    setUpdatedTree(prevTree => {
      return {
        ...prevTree!,
        imageIds: prevTree!.imageIds.filter((_, i) => i !== index)
      }
    });
  };

  const openImageDialog = (imagePreview: ImagePreview) => {
    setSelectedImage(imagePreview);
    setImageDialogIsOpen(true);
  };

  const closeImageDialog = () => {
    setImageDialogIsOpen(false);
  };

  const handleDeleteTreeClick = () => {
    setDeleteDialogOpen(true);
  }

  const handleDeleteTreeConfirm = () => {
    setDeleteDialogOpen(false);
    setIsSaving(true);
    deleteTree(navigate, savedTree.estimateId, savedTree.id)
      .then(() => {
        props.onTreeDelete();
      })
      .catch(e => {
        enqueueSnackbar('Error deleting tree', {variant: 'error', autoHideDuration: 5000})
        logError('Error deleting tree', {}, e);
      })
      .finally(() => {
        setIsSaving(false);
      })
  }

  const handleDeleteTreeClose = () => {
    setDeleteDialogOpen(false);
  }

  const handleNewTree = async () => {
    await saveTreeAndExecute(setIsSaving, props.onNewTree)
  }

  const handleNewGate = async () => {
    await saveTreeAndExecute(setIsSaving, props.onNewGate)
  }

  const handleFinishEstimate = async () => {
    await saveTreeAndExecute(setIsFinishing, props.onFinishEstimate)
  }

  const allRequiredValuesSet = () => {
    const treeValuesSet = updatedTree.height && updatedTree.width && updatedTree.trunkDiameter;
    const operationValuesSet = allOperationsValid();
    return treeValuesSet && operationValuesSet;
  }

  const treeHeightBreakpoints = [...Array.from(Array(100).keys())]
  const treeWidthBreakpoints = [...Array.from(Array(100).keys())]
  const trunkDiameterBreakpoints = [...Array.from(Array(100).keys())]
  const speciesList = ['Alder', 'Ash', 'Cedar', 'Cherry', 'Cottonwood', 'Douglas Fir', 'Elm', 'Fir', 'Hemlock', 'Lodgepole Pine', 'Maple', 'Ponderosa Pine', 'Spruce', 'Willow', 'Deciduous (Generic)', 'Evergreen (Generic)', 'Ornamental (Generic)']

  const generateMenuItemsFromBreakpoints = (breakpoints: number[], unit: string) => {
    const empty = <MenuItem value={''} key={'EMPTY'}></MenuItem>
    const items = breakpoints.map((breakpoint, index) => {
      const value = `${breakpoint} ${unit}`;
      return <MenuItem value={value} key={value}>{value}</MenuItem>
    });
    return [empty, ...items];
  }

  const getOperationDetailInputs = (operationType?: TreeOperationType) => {
    if(!operationType) return <></>;
    const inputSchema = TreeOperationSchema[operationType];
    const fields = inputSchema.map(schema => {
      const defaultValue =
        schema.options ? schema.options[0] :
        (InputDataType.Number === schema.dataType || InputDataType.Percentage === schema.dataType) ? 0 : '';
      if(selectedUpdatedOperationDetails!.details[schema.name] === null) {
        setSelectedUpdatedOperationDetails(prev => ({...prev!, details: {...prev!.details, [schema.name]: defaultValue}}))
      }

      return buildInput(schema, () => selectedUpdatedOperationDetails!.details[schema.name] ?? defaultValue, (value) => {
        setSelectedUpdatedOperationDetails(prev => ({...prev!, details: {...prev!.details, [schema.name]: value}}))
      });
    });
    return fields;
  }

  const handleTakeMeasuredPictureClick = async () => {
    const token = await getUserTokenOrRedirect(navigate)
    const appScheme = `https://www.accusitellc.com/measure-tree?token=${token}&tree-id=${savedTree.id}`;
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes('android');
    if (isAndroid) {
      window.location.replace(appScheme);
    } else {
      alert('This feature is only available on Android devices.');
    }
  }

  return (
    <Card
      sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <CardHeader title={`Tree ${props.treeLocalId}`}/>
        {operationIsLoading ? <CenteredCircularSpinner sx={{minHeight: '950px'}}/> :
        <Stack spacing={2}>
          <ImagePreviews images={treeImagePreviews} removeImage={removeTreeImage} openImageDialog={openImageDialog} countTreeImagesLoading={countTreeImagesLoading} />
          <Button variant="contained" component="label">
            Add Picture
            <input type="file" hidden multiple onChange={handleTreeImageChange} />
          </Button>
          <Button onClick={handleTakeMeasuredPictureClick} variant="contained" component="label">
            Take Measured Picture
          </Button>
          <div style={{display: 'flex', gap: '10px'}} >
            {updatedOperationsDetails.map((operation, i) =>
              <Chip
                key={i}
                color={i === selectedOperationIndex ? 'primary' : 'default'}
                onClick={() => handleSelectedOperationChipClick(i)}
                style={{flexGrow: 0}} label={`${i + 1}: ${operation?.operationType ?? 'Operation'}`}/>)}
          </div>
          <FormControl fullWidth>
            <InputLabel required={false} id="tree-operation-label">Operation</InputLabel>
            <Select
              labelId="tree-operation"
              id="tree-operation-select"
              value={selectedUpdatedOperationDetails?.operationType ?? ''}
              label="Tree Operation"
              onChange={handleOperationTypeChange}
            >
              {Object.values(TreeOperationType).map((operationType) =>
                <MenuItem value={operationType} key={operationType}>{operationType}</MenuItem>)}
            </Select>
          </FormControl>
          {!operationIsLoading && getOperationDetailInputs(selectedUpdatedOperationDetails?.operationType)}
          {!operationIsLoading && <TextField
            required={false}
            disabled={!selectedUpdatedOperationDetails}
            label="Estimated Cost ($)"
            variant="outlined"
            value={selectedUpdatedOperationDetails?.estimatedCost ?? ''}
            onChange={handleEstimatedCostChange}
          />}
          <Button onClick={handleDeleteOperationClick} disabled={updatedOperationsDetails.length < 2} variant="contained" color="error" style={selectedOperationIsValid() ? ({color: 'black'}) : ({})} component="label">
            {isSaving ? <CircularProgress/> : 'Delete Operation'}
          </Button>
          <Button onClick={handleAddAnotherOperationClick} disabled={!selectedOperationIsValid()} variant="contained" component="label">
            Add Another Operation
          </Button>

          <Divider orientation="horizontal" flexItem />

          <FormControl fullWidth>
            <InputLabel required={true} id="tree-height-select-label">Tree Height</InputLabel>
            <Select
              labelId="tree-height"
              id="tree-height-select"
              value={updatedTree.height ?? '0 Feet'}
              label="Tree Height"
              onChange={handleTreeHeightChange}
            >
              {generateMenuItemsFromBreakpoints(treeHeightBreakpoints, 'Feet')}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel required={true} id="tree-width-select-label">Tree Width</InputLabel>
            <Select
              labelId="tree-width"
              id="tree-width-select"
              value={updatedTree.width ?? '0 Feet'}
              label="Tree Width"
              onChange={handleTreeWidthChange}
            >
              {generateMenuItemsFromBreakpoints(treeWidthBreakpoints, 'Feet')}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel required={true} id="tree-trunk-diameter-select-label">Trunk Diameter</InputLabel>
            <Select
              labelId="tree-trunk-diameter"
              id="tree-trunk-diameter-select"
              value={updatedTree.trunkDiameter ?? '0 Inches'}
              label="Tree Trunk Diameter"
              onChange={handleTreeTrunkDiameterChange}
            >
              {generateMenuItemsFromBreakpoints(trunkDiameterBreakpoints, 'Inches')}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              freeSolo
              options={speciesList}
              value={updatedTree.species ?? ''}
              onInputChange={(_, v) => handleTreeSpeciesChange(v)}
              renderInput={(params) => <TextField required={false} {...params} label="Tree Species"/>}
            />
          </FormControl>
          <TextField
            label="Tree Notes"
            variant="outlined"
            multiline
            rows={2}
            value={updatedTree?.notes ?? ''}
            onChange={(e) => setUpdatedTree({...updatedTree!, notes: e.target.value})}
          />
          <TextField
            label="Crew Notes"
            variant="outlined"
            multiline
            rows={2}
            value={updatedTree?.crewNotes ?? ''}
            onChange={handleCrewNotesChange}
          />

          <Button onClick={handleDeleteTreeClick} variant="contained" color="error" style={{color: 'black'}} component="label">
            {isSaving ? <CircularProgress/> : 'Delete Tree'}
          </Button>
          <Button disabled={!allRequiredValuesSet()} onClick={handleNewTree} variant="contained" component="label">
            {isSaving ? <CircularProgress/> : 'Save and Add New Tree'}
          </Button>
          <Button disabled={!allRequiredValuesSet()}  onClick={handleNewGate} variant="contained" component="label">
            {isSaving ? <CircularProgress/> : 'Save and Add Gate'}
          </Button>
          <Button disabled={!allRequiredValuesSet()} onClick={handleFinishEstimate} variant="outlined" component="label">
            {isFinishing ? <CircularProgress/> : 'Save and Return To Estimate'}
          </Button>
        </Stack>}
      </CardContent>
      <Dialog
        open={imageDialogIsOpen}
        onClose={closeImageDialog}
        PaperProps={{
          style: { maxWidth: '100%', maxHeight: '100%' },
        }}
      >
        <DrawableImage image={selectedImage!} onSave={handleImageUpdated} isSaving={treeImageIsUpdating || operationImageIsUpdating} onClose={closeImageDialog} />
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteTreeClose}>
        <DialogTitle>Delete Tree?</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-around', padding: '8px' }}>
          <Button onClick={handleDeleteTreeClose} variant="contained" color="primary" style={{color: 'black'}}>
            Cancel
          </Button>
          <Button onClick={handleDeleteTreeConfirm} variant="contained" color="error" style={{color: 'black'}} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
});

export default EstimateTreeDetail;
